var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var client_exports = {};
__export(client_exports, {
  appflowInstance: () => appflowInstance
});
module.exports = __toCommonJS(client_exports);
var import_react = __toESM(require("react"));
var import_client = require("react-dom/client");
var import_server = require("react-dom/server");
var import_cssinjs = require("@ant-design/cssinjs");
var import_react_router_dom = require("react-router-dom");
var import_server2 = require("react-router-dom/server");
var import_utils = require("utils");
var import_componentEntrypoints = __toESM(require("./componentEntrypoints"));
const { RootElement } = import_utils.components;
let windowPolyfill;
if (typeof window !== "undefined") {
  windowPolyfill = window;
} else {
  windowPolyfill = {
    location: {
      pathname: ""
    }
  };
}
if (!windowPolyfill.AppFlow) {
  windowPolyfill.AppFlow = {};
}
const applicationName = process.env.APPLICATION;
const applicationConfig = process.env.APPLICATION_CONFIG;
const {
  config: applicationSpecificConfig,
  components: applicationComponents = {},
  allComponentTypes = [],
  pages = {}
} = applicationConfig;
const defaultInitialProps = {
  rootElement: null,
  config: {},
  additionalStyles: [],
  hydrate: false,
  shadow: true,
  specificComponent: ""
};
let appflowInstance = {
  renderToString: import_server.renderToString,
  render: ({ targetRoot, config = {}, specificComponent = "", specificRoute = "", hydrate = false, basePath = "" }) => {
    let initialConfig = Object.assign({}, applicationSpecificConfig, config);
    let pageElements = {};
    let pageEvents = {};
    Object.keys(pages).forEach((page) => {
      const { routes = [], elements = [], events = {} } = pages[page];
      routes.forEach((route) => {
        const { path } = route;
        pageElements[path] = elements;
        pageEvents[path] = events || [];
      });
    });
    let rootElement;
    if (specificComponent) {
      const basename = basePath;
      rootElement = [
        import_react.default.createElement(import_server2.StaticRouter, {
          location: "/",
          key: "router",
          basename,
          children: [
            import_react.default.createElement(RootElement, {
              applicationConfig,
              initialConfig,
              elements: [applicationComponents[specificComponent]],
              componentEntrypoints: import_componentEntrypoints.default
            })
          ]
        })
      ];
    } else {
      const basename = basePath;
      if (process.env.APPFLOW_SERVER === "true") {
        rootElement = [
          import_react.default.createElement(import_server2.StaticRouter, {
            location: specificRoute,
            key: "router",
            basename,
            children: [
              import_react.default.createElement(RootElement, {
                applicationConfig,
                initialConfig,
                elements: pageElements[specificRoute],
                events: pageEvents[specificRoute],
                componentEntrypoints: import_componentEntrypoints.default
              })
            ]
          })
        ];
      } else {
        const routes = Object.keys(pageElements).map((path) => ({
          path,
          element: import_react.default.createElement(RootElement, {
            applicationConfig,
            initialConfig,
            elements: pageElements[path],
            events: pageEvents[path],
            componentEntrypoints: import_componentEntrypoints.default
          })
        }));
        const router = specificRoute ? (0, import_react_router_dom.createMemoryRouter)(routes, {
          basename,
          initialEntries: [specificRoute]
        }) : (0, import_react_router_dom.createBrowserRouter)(routes, { basename });
        rootElement = [
          import_react.default.createElement(import_react_router_dom.RouterProvider, {
            router,
            key: "router"
          })
        ];
      }
    }
    const cache = (0, import_cssinjs.createCache)();
    return hydrate ? { rootNode: rootElement } : {
      rootNode: import_react.default.createElement(import_cssinjs.StyleProvider, { container: targetRoot, cache }, rootElement),
      cache
    };
  },
  init: async (initialProps = defaultInitialProps) => {
    const {
      rootElement,
      config = {},
      additionalStyles = [],
      hydrate = false,
      shadow = false,
      specificComponent = "",
      specificRoute = "",
      basePath = ""
    } = initialProps;
    let targetRoot = rootElement;
    if (shadow) {
      let container = document.createElement("div");
      container.className = "appflow-container";
      const shadowRoot = container.attachShadow({ mode: "open" });
      rootElement.append(container);
      config.rootElement = shadowRoot;
      targetRoot = shadowRoot;
    } else {
      config.rootElement = document.body;
    }
    if (process.env.APPFLOW_STYLES) {
      const css = document.createElement("style");
      css.appendChild(document.createTextNode(process.env.APPFLOW_STYLES));
      targetRoot.appendChild(css);
    }
    if (process.env.APPFLOW_LOCAL_URL !== "") {
      additionalStyles.push(`//${process.env.APPFLOW_LOCAL_URL}/client-init.css`);
    }
    await (0, import_utils.loadStyles)(shadow ? targetRoot : document.head, additionalStyles);
    const { rootNode } = appflowInstance.render({
      targetRoot,
      config,
      specificComponent,
      specificRoute,
      hydrate,
      componentEntrypoints: import_componentEntrypoints.default,
      basePath
    });
    if (hydrate) {
      (0, import_client.hydrateRoot)(targetRoot, rootNode);
    } else {
      (0, import_client.createRoot)(targetRoot).render(rootNode);
    }
  },
  /**
   * Create individual init and initShadow entrypoints for each global component within application config.
   * This sets 'specificComponent' to match one of the available components within application.json
   */
  components: Object.keys(applicationComponents).reduce((allComponents, componentKey) => {
    const componentInstance = {
      init: async (initialProps = defaultInitialProps) => {
        return appflowInstance.init(
          Object.assign({}, initialProps, {
            specificComponent: componentKey
          })
        );
      }
    };
    return Object.assign(allComponents, {
      [componentKey]: componentInstance
    });
  }, {})
};
windowPolyfill.AppFlow[process.env.APPLICATION] = appflowInstance;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  appflowInstance
});
