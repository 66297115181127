var import_client = require("./client.js");
var import_client2 = require("./client.css");
const applicationName = process.env.APPLICATION;
const getBasePath = () => {
  const scriptSrc = document.getElementById("client-init")?.src;
  if (!scriptSrc) {
    return "";
  }
  const scriptUrl = new URL(scriptSrc, window.location.origin);
  return scriptUrl.pathname.substring(0, scriptUrl.pathname.lastIndexOf("/") + 1);
};
window.AppFlow[applicationName].init({
  rootElement: document.getElementById("root"),
  config: {},
  additionalStyles: [],
  hydrate: true,
  shadow: false,
  basePath: getBasePath()
});
